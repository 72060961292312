import React, {Component} from 'react';

export default class Trail extends Component {
   

    render(){
        const {trail_to, y_pos, x_pos, is_trail} = this.props;

        if(is_trail == 1){
            return <path />
        }

        let key = 0;
        switch(true){
            case window.outerWidth > 1024:
                key = 0
                break;
            case window.outerWidth < 1024 && window.outerWidth > 767:
                key = 1;
                break;
            case window.outerWidth < 768:
                key = 2;
                break;
            default:
                key = 0
        }

        const x1 = (window.innerWidth / 100) * x_pos[key]
        const y1 = (window.innerHeight / 100) * y_pos[key]

        const x2 = (window.innerWidth / 100) * JSON.parse(trail_to.x_pos)[key]
        const y2 = (window.innerHeight / 100) * JSON.parse(trail_to.y_pos)[key]

        var p1y = y1;
        var p1x = x1;
        var p2y = y2;
        var p2x = x2;
        // mid-point of line:
        var mpx = (p2x + p1x) * 0.5;
        var mpy = (p2y + p1y) * 0.5;

        // angle of perpendicular to line:
        var theta = Math.atan2(p2y - p1y, p2x - p1x) - Math.PI / 2;

        // distance of control point from mid-point of line:
        var offset = (Math.random() * 300) - 300

        // location of control point:
        var c1x = mpx + offset * Math.cos(theta);
        var c1y = mpy + offset * Math.sin(theta);

        var c2x = mpx - offset * Math.cos(theta);
        var c2y = mpy - offset * Math.sin(theta);

        // show where the control point is:


        // construct the command to draw a quadratic curve
        const curve = "M " + p1x + ", " + p1y + " C " + c1x + " " + c1y + ", "+ c2x + " " + c2y + "," + p2x + " " + p2y;
        
        return <path id="curve" className="trail" d={curve}/>
    }
}