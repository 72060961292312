import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import fitty from 'fitty'

export default class Bubble extends Component {
    constructor(props){
        super(props);
        
        this.myRef = React.createRef();
        this.wrapper = React.createRef();
        const {percent} = props;
        
        let centerX = 0
        let centerY = 0

        if(props.wrapper){
            const parent = props.wrapper
        
            centerX = parent.offsetLeft + (parent.offsetWidth / 2)
            centerY =  parent.offsetTop + (parent.offsetHeight / 2)
            this.centerX = parent.offsetLeft + (parent.offsetWidth / 2)
            this.centerY = parent.offsetTop + (parent.offsetHeight / 2)
        }

        this.state = {
            show: false,
            bubbles: null,
            fade: false,
            childrenBoundries: [],
            position: [],
            size: percent / 2,
            posx: centerX ? centerX : 0,
            posy: centerY ? centerY : 0,
            fontSize: '16px',
            textFitted: false,
            showText: false,
            currentVideoTime: 0,
            gutter: 5,
            dragging: false,
            transition: false,
            opacity: 0,
            active: true,
            bubbleOutput: []
        }
        this.showFullscreen = this.showFullscreen.bind(this)
        this.changeAppBg = this.changeAppBg.bind(this)
        this.showBubble = this.showBubble.bind(this)
        
    } 

    componentDidMount(){
        
        const {percent, size, y_pos, x_pos} = this.props;
        
        let key = 0;
        switch(true){
            case window.outerWidth > 1024:
                key = 0
                break;
            case window.outerWidth < 1024 && window.outerWidth > 767:
                key = 1;
                break;
            case window.outerWidth < 768:
                key = 2;
                break;
            default:
                key = 0
        }
        

        let currentSize = (percent / 10) * 2
        
        if(size !== undefined){
            currentSize = size[key]
        }else{
            this.setSize()
        }
        
        if(x_pos === undefined && y_pos === undefined){
            this.setPosition()
        }else{

            this.setState({
                size: currentSize + 'vh',
                posx: (window.innerWidth / 100) * x_pos[key],
                posy: (window.innerHeight / 100) * y_pos[key],
                transition: true,
                opacity: 1
            })

        }

        
        if(this.myRef.current.querySelector('.bubble-animation')){
            const speed = 6
            this.myRef.current.querySelector('.bubble-animation').animate([
                // keyframes
                { transform: 'translate(0, 0)' }, 
                { transform: 'translate(' + Math.random() * (speed - 0) + 0 +'%, ' + Math.random() * (speed - 0) + 0 +'%' },
                { transform: 'translate(' + Math.random() * (speed - 0) + 0 +'%, ' + Math.random() * (speed - 0) + 0 +'%' },
                { transform: 'translate(' + Math.random() * (speed - 0) + 0 +'%, ' + Math.random() * (speed - 0) + 0 +'%' },
                { transform: 'translate(0,0)' }
            ], { 
                // timing options
                duration: 20000,
                iterations: Infinity,
                easing: 'ease'
            });
        }

        this.getBubbles()
        
    }

    setSize(){
        
        const {percent} = this.props
        const parent = this.myRef.current.closest('.bubble-wrapper')

        if(parent){
            this.setState({
                size: (percent / 100) * parent.offsetHeight + 'px'
            })
        }
        
    }

    componentDidUpdate(){
        const self = this;
        const {textFitted} = this.state
        
        if(textFitted) return true;
        
        setTimeout(function(){
            if(self.myRef.current){
                if(self.myRef.current.querySelector('.bubble-text-large')){
                    let max = 120
                    switch(true){
                        case window.innerWidth > 1024:
                            max = 120
                            break;
                        case window.innerWidth < 1024 && window.innerWidth > 767:
                            max = 100;
                            break;
                        case window.innerWidth < 768:
                            max = 60;
                            break;
                        default:
                            max = 120
                    }
                    fitty(self.myRef.current.querySelector('.bubble-text-large'), {
                        minSize:14,
                        maxSize: max
                    })
                    //if(self.myRef.current.querySelector('.bubble-text-wrapper')){
                        
                        const fontSize = getComputedStyle(self.myRef.current.querySelector('.bubble-text-large'), null).getPropertyValue('font-size')
                        self.setState({
                            fontSize: fontSize
                        })
                        
                        
                    
                    
                }

                self.setState({
                    textFitted: true,
                    showText: true
                })
            }
        }, 600)
        
        
    }

    setPosition(){
        const parent = this.myRef.current.closest('.bubble-wrapper')
        const centerX = parent.offsetLeft + (parent.offsetWidth / 2)
        const centerY =  parent.offsetTop + (parent.offsetHeight / 2)
        this.centerX = parent.offsetLeft + (parent.offsetWidth / 2)
        this.centerY = parent.offsetTop + (parent.offsetHeight / 2)
        this.setState({
            posx: centerX,
            posy: centerY
        })

        const {i} = this.props;
        const self = this
        if(i === 0){
            
            this.setState({
                transition: false,
                opacity: 1
            })

            setTimeout(function(){
                self.setState({
                    transition: true,
                })
            }, 500)
            return;
        }
        
        this.calculatePosition()
    }

    calculatePosition(){
        const self = this
        let {wrapper} = this.props
        if(!wrapper){
            wrapper = this.myRef.current.closest('.bubble-wrapper')
        }

        const {gutter} = this.state;

        if(gutter < 25){
            this.setState({
                //gutter: gutter + 5
            })
        }
        let main = wrapper.querySelector('.bubble:first-of-type');

        const item = this.myRef.current;
        
        const radius = main.offsetHeight / 2;
        const random = Math.random() * (5 - 0 + 1) + 0

        const size = item.offsetHeight
        const offset = radius + (size / 2) + 5;
        //const offset = radius + (size / 2) + Math.random() * (60 - 20) + 20;
        
        this.setState({
            posx: (radius - Math.round(offset * (Math.cos(random))) + (this.centerX - radius)),
            posy: (radius + Math.round(offset * (Math.sin(random))) + (this.centerY - radius)),
        })
        
        setTimeout(function(){
            self.checkColliding()
        }, 200)
            
        
    }

    checkColliding(){
        let {wrapper} = this.props
        const self = this.myRef.current
        if(!wrapper){
            let doesExist = self.closest('.bubble-wrapper');
            if(doesExist){
                wrapper = doesExist;
            }
        }
        let children = wrapper.children;

        for(let item of children){

            if(self !== item){
                if(this.isColliding(self, item) || this.isOutside(self)){
                    this.calculatePosition()
                    return
                }
            }
        }

        this.setState({
            opacity: 1,
            transition: true
        })
        
    }

    isOutside(item){
        let {wrapper} = this.props
        if(!wrapper){
            wrapper = this.myRef.current.closest('.bubble-wrapper')
        }
        const parent = wrapper
        const size = item.offsetWidth / 4
        const left = parent.offsetLeft
        const right = parent.offsetLeft + parent.offsetWidth
        const top = parent.offsetTop
        const bottom = parent.offsetTop + parent.offsetHeight

        

        if(
            (item.offsetLeft + size) > right ||
            (item.offsetLeft - size) < left ||
            (item.offsetTop - size) < top ||
            (item.offsetTop + size) > bottom
        ){
            return true;
        }
        
        return false
        
    }


    isColliding(item1, item2){
        let distance_x = item1.offsetLeft - item2.offsetLeft;
        let distance_y = item1.offsetTop - item2.offsetTop;
        let radii_sum  = (item1.offsetHeight / 2) + (item2.offsetHeight / 2);
        if (distance_x * distance_x + distance_y * distance_y <= radii_sum * radii_sum) return true;

        return false;
    }

    showFullscreen() {
        const node = this.myRef.current;
        node.style.zIndex = 10
        if(node.querySelector('video')){
            this.setState({
                currentVideoTime: node.querySelector('video').currentTime
            })
        }

        
        node.classList.add('full');
        setTimeout(() => {
            if(node.classList.contains('full')){
                this.setState({
                    show: true,
                    transition: true
                })  
            }
        }, 500);
        
    }

    handleVideoMounted = element => {
        const {currentVideoTime} = this.state
        if(element !== null){
            element.currentTime = currentVideoTime
        }
    }

    showBubble(){
        const node = this.myRef.current;
        
        node.classList.add('fadeout');
        const self = this
        
        setTimeout(() => {
            self.setState({
                show: false,
                fade: true,
                bubbles: null
            })  
        }, 200);

        setTimeout(() => {
            node.classList.remove('full');
        }, 350);
    }

    renderBubble(){ 
        const {title, text, color, percent, photo, introtext, video, trail_to, changeAppBg, i} = this.props
        const {fade, size, posy, posx, fontSize, showText, transition, opacity} = this.state

        changeAppBg()

        let classes = ['bubble'];

        if(fade){
            classes.push('full')
            classes.push('transition')
        }

        if(transition){
            classes.push('transition')
        }
        
        if(parseInt(size) > 3){
            return (
                <div ref={this.myRef} className={classes.join(' ')} style={{height: size, width: size, top: posy, left: posx, opacity: opacity, zIndex: i}}>
                    <div className="bubble-animation">
                    <div className="bubble-content" style={{backgroundColor: color, backgroundImage: 'url("//sparebank1stiftelsenjln.no/'+photo+'")'}} onClick={() => this.showFullscreen()}>
                        {video !== null ? (
                            <video muted autoPlay playsInline loop ref={this.handleVideoMounted}>
                                <source src={'//sparebank1stiftelsenjln.no/' + video} /> 
                            </video>
                        ) : null}
                        <div className="bubble-overlay" style={{backgroundColor: color}}></div>
                        <div className="bubble-text-wrapper" style={{fontSize: fontSize, opacity: showText ? 1 : 0}}>
                            <span className="bubble-text bubble-text-small">{title}</span>
                            <span className="bubble-text bubble-text-large">{text}</span>
                            <span className="bubble-text bubble-text-small">{introtext}</span>
                        </div>
                    </div>
                    </div>
                </div>
            )
        }else{
            classes.push('bubble-tiny')
            return (
                <div ref={this.myRef} className={classes.join(' ')} style={{height: size, width: size, top: posy, left: posx}} onClick={() => this.showFullscreen()}>
                    <span className="bubble-text bubble-text-small" style={{color: color}}>{title}</span>
                    <div className="bubble-content" style={{fontSize: percent + 'px', backgroundColor: color, backgroundImage: 'url("//sparebank1stiftelsenjln.no/'+photo+'")'}}></div>
                    <span className="bubble-text bubble-text-large" style={{color: color}}>{text}</span>
                    <span className="bubble-text bubble-text-small" style={{color: color}} dangerouslySetInnerHTML={{ __html: introtext }}></span>
                </div>
            )
        }
    }
    //animation: 'float 6s ease-in-out infinite'
    
    getBubbles(){
        
        const self = this
        const {id, setBg} = this.props
        const {bubbles} = this.state

        if(bubbles === null){
            axios.post('https://api.sparebank1stiftelsenjln.no/api/superajax', {
                snippet: 'getResourceApi',
                id: id
            })
            .then(function (response) {
                self.setState({bubbles: response.data})
                self.renderBubbles()
            })
        }
    }

    changeAppBg(){
        const {color, photo, video, app} = this.props
        if(app){
            let value = ''
            switch(true){
                case video !== null && video !== '':
                    value = 'video'
                    break
                case photo !== null && photo !== '':
                    value = 'image'
                    break;
                default:
                    value = color

            }
            app.current.setAttribute("data-active-bg", value); 
        }
    }

    renderBubbles(){
        
        const {bubbles} = this.state
        const {updateRefs, app, goHome} = this.props
        let bubbleOutput = [];
        let i = 0

        if(bubbles !== null){
            
            bubbles.map((b) => {
                const ref = React.createRef()
                bubbleOutput.push(<Bubble
                    key={b.id}
                    i={i++}
                    id={b.id}
                    title={b.title}
                    introtext={b.introtext}
                    text={b.text}
                    sum={b.sum}
                    color={b.color}
                    content={b.content}
                    percent={b.percent}
                    parent={b.parent}
                    photo={b.photo}
                    video={b.video}
                    parent_node={this.myRef.current}
                    wrapper={this.wrapper.current}
                    onChange={this.handleChangeValue}
                    changeAppBg={this.changeAppBg}
                    updateRefs={updateRefs}
                    goHome={goHome}
                    ref={ref}
                    app={app}
                />)

                updateRefs(ref)
            });

            this.setState({
				bubbleOutput: bubbleOutput,
			})
        }
    }

    renderFullscreen(){
        
        const {title, text, color, content, parent, photo, video, goHome} = this.props
        const {bubbleOutput} = this.state
		
        
        
        this.changeAppBg()    
        
        
        return (
            <div ref={this.myRef} className="bubble-container text-left" style={{backgroundColor: color, backgroundImage: 'url("//sparebank1stiftelsenjln.no'+photo+'")'}}>
                {video !== null ? (
                        <video muted playsInline autoPlay loop ref={this.handleVideoMounted}>
                            <source src={'//sparebank1stiftelsenjln.no/' + video} /> 
                        </video>
                        ) : null}
                <div className="bubble-overlay" style={{backgroundColor: color}}></div>
                <div className="container-fluid">
                    <div className="stuff align-items-md-center justify-content-lg-end">
                        <div className="left">
                            <div className="text-content animateBottom">
                                
                                <button className="btn btn-link m-0 p-0" onClick={() => this.showBubble()}>
                                    <h2>
                                        <FontAwesomeIcon icon={['fal', 'arrow-circle-left']}   color={"#fff"}/>
                                        <span className="text-white ml-2">{parent}</span>
                                    </h2>
                                </button>
                                <h1 className="text-white">{title}: {text}</h1>
                                <div className="text-content" dangerouslySetInnerHTML={{ __html: content }}></div>
 
                            </div>
                        </div>
                        <div className="right">
                            <div className="bubble-wrapper" ref={this.wrapper}>
                                {bubbleOutput}
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }

    render(){
        const {show} = this.state

        
        return show === false ? this.renderBubble() : this.renderFullscreen()
    }
}