import React, {Component} from 'react';
import './App.css';
import Bubble from './components/Bubble'
import Trail from './components/Trail'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
library.add(fal,fas);

export default class extends Component {


	constructor(props){
		super(props)

		this.state = {
			bubbles: null,
			activeBg: 'image',
			childrenRefs: [],
			bubbleOutput: [],
			pathOutput: '',
			mainBg: ''
		}
		this.myRef = React.createRef()
		this.changeAppBg = this.changeAppBg.bind(this)
		this.updateRefs = this.updateRefs.bind(this)
		this.goHome = this.goHome.bind(this)

		
	}

	componentDidMount(){

		this.getBubbles()
		
	}

	getBubbles(){
		
		const self = this;
		let metaImage = document.querySelector('meta[name="mainbg"]').getAttribute('content');

		if(metaImage == ""){
			metaImage = process.env.PUBLIC_URL + '/gfx/bg.jpg';
		}

		axios.post('https://api.sparebank1stiftelsenjln.no/api/superajax', {
            snippet: 'getResourceApi',
			id: document.querySelector('meta[name="modresource"]').getAttribute('content')
		})
		.then(function (response) {
			self.setState({
				bubbles: response.data, 
				mainBg: metaImage
			})
			self.renderBubbles()
		})

		
	}

	componentDidUpdate(){
		
	}

	updateRefs(ref){
		this.setState(prevState => ({
			childrenRefs: [...prevState.childrenRefs, ref]
		  }))
	}

	changeAppBg(){
		this.myRef.current.setAttribute("data-active-bg", "start"); 
	}

	goHome(){
		const self = this
		this.state.childrenRefs.map((c) => {
			if(c.current){
				c.current.showBubble()
			}
		})
		setTimeout(() => {
			this.setState({
				childrenRefs: [],
				bubbles: null
			})
			this.getBubbles()
		}, 500)
		
	}

	renderBubbles(){
		
		const {bubbles} = this.state;
		
		let bubbleOutput = [];
		let paths = '';
		let i = 0;
		let trail_to;
		if(bubbles !== null){
			bubbles.map((b) => {
				const ref = React.createRef()
				bubbleOutput.push(<Bubble
					key={b.id}
					i={i++}
					id={b.id}
					title={b.title}
					text={b.text}
					introtext={b.introtext}
					sum={b.sum}
					color={b.color}
					content={b.content}
					percent={b.percent}
					parent={b.parent}
					parent_node={null}
					photo={b.photo}
					video={b.video}
					size={JSON.parse(b.size)}
					y_pos={JSON.parse(b.y_pos)}
					x_pos={JSON.parse(b.x_pos)}
					trail_to={b.trail_to}
					app={this.myRef}
					changeAppBg={this.changeAppBg}
					updateRefs={this.updateRefs}
					ref={ref}
					goHome={this.goHome}
				/>)

				this.updateRefs(ref)
			});

			bubbles.map(function(b){
				if(b.trail_to == 1){
					trail_to = b
				}
			})

			paths = bubbles.map((b) => 
				<Trail 
					key={b.id}
					trail_to={trail_to}
					is_trail={b.trail_to}
					y_pos={JSON.parse(b.y_pos)}
					x_pos={JSON.parse(b.x_pos)}
				/>
			)

			//Hvis årsregnskapet er tomt, skriver vi ut en placeholder
			if(bubbleOutput.length === 0){
				bubbleOutput.push(<Bubble
					key={1}
					i={i++}
					id={1}
					title={''}
					text={'Tomt'}
					introtext={'Årsregnskapet er tomt'}
					sum={0}
					color={'#002776'}
					content={'Vi kunne ikke finne noe årsregnskap i valgt ressurs. Prøv å legge inn innholdet ditt i valgt ressurs, eller velg en annen.'}
					percent={0}
					parent={null}
					parent_node={null}
					size={[50, 50, 50]}
					y_pos={[50, 50, 50]}
					x_pos={[50, 50, 50]}
					app={this.myRef}
					changeAppBg={this.changeAppBg}
					updateRefs={this.updateRefs}
					goHome={null}
				/>)
			}

			this.setState({
				bubbleOutput: bubbleOutput,
				pathOutput: paths
			})
		}
	}

	render(){
		
		const {pathOutput, bubbleOutput, mainBg} = this.state

		return(
			<div className="App" data-active-bg="image" ref={this.myRef}>
				<header className="App-header" style={{backgroundImage: 'url("' + mainBg + '")'}}>
					<svg className="trails" xmlns="http://www.w3.org/2000/svg">
						{pathOutput}
					</svg>
					{bubbleOutput}
					<div className="container-fluid">
						<div className="stuff align-items-center justify-content-lg-end">
							
							<div className="right">
								<div className="bubble-wrapper">
									
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		)
	}
}

